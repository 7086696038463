<template>
  <v-app>
    <dashboard-core-app-bar />
    <dashboard-core-drawer />

    <v-main>
      <v-container fluid>
        <route-breadcrumb />
        <router-view />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "DashboardIndex",

  components: {
    RouteBreadcrumb: () => import("./RouteBreadcrumb"),
    DashboardCoreAppBar: () => import("./AppBar"),
    DashboardCoreDrawer: () => import("./Drawer")
  },
  methods: {},
  data: () => ({}),
  computed: {},
  mounted() {}
};
</script>

<style lang="scss"></style>
